import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Is it possible to programme a camera tour in Synology?",
  "path": "/Frequently_Asked_Question/Synology_Automated_Pan_and_Tilt_Tour/",
  "dateChanged": "2021-08-04",
  "author": "Mike Polinowski",
  "excerpt": "Is it possible to save the camera positions in the PTZ control in the Synology and create the tour? The best thing would be to save the zoom setting per position. And then only move to the positions at certain times or run the tour the whole day by schedule.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Is it possible to programme a camera tour in Synology – INSTAR Wiki' dateChanged='2021-08-04' author='Mike Polinowski' tag='INSTAR IP Camera' description='Is it possible to save the camera positions in the PTZ control in the Synology and create the tour? The best thing would be to save the zoom setting per position. And then only move to the positions at certain times or run the tour the whole day by schedule.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Synology_Automated_Pan_and_Tilt_Tour/' locationFR='/fr/Frequently_Asked_Question/Synology_Automated_Pan_and_Tilt_Tour/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "is-it-possible-to-programme-a-camera-tour-in-synology",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#is-it-possible-to-programme-a-camera-tour-in-synology",
        "aria-label": "is it possible to programme a camera tour in synology permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Is it possible to programme a camera tour in Synology?`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`:  Is it possible to save the camera positions in the PTZ control in the Synology and create the tour? The best thing would be to save the zoom setting per position. And then only move to the positions at certain times or run the tour the whole day by schedule.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Yes, you can! Just select the camera and then go to PTZ control:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "807px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/adfceb2629f0a577d32f3f76d1ebfd51/d2a60/Syn1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaklEQVQ4y62TW27TQBSGvUI2wApYEwtAIFQVVAGCgkQRFdAmTZubkya+j8f2XOy45QWEPmSnSekFWpU+fD5jz5x/zn8848zlgm8Dj0lseba9w+7RnC9HM/q+pTstOJhpenO9jqvx33C6bsAsLojykiDVa/xUk5qaMLN4QrfzzbfVOJCGKLfERUmcn+OIrCBMMmRhSTJFIgtkofH8kJHrEwuJzHMyZUnSnCiRyMIg2nWKQmsKpZdRa5xvk4iPvQl70xhXVvSCnLmueflkg0cPHvJ06zPbh5bDQLRzQ2HoJ5p+Yq7FiRJBECcoY1typVGmZNAf8Or5C7Z2UnbHJ/RDy1FYMRKnLRNZMc3KKzhSSoQQWGuw1qK1pioNw5lgc3vAIDxllv/CFT9bxknDD7z8hEAtruCINCWMIpTSa8E8V/QnJWnWbKJR2qDN7XCESPH8gKIoKMsSrRVZrtDaUJYGY2y70fWYK7SCURyvFzUVKqXayUbMmFW8HU4qJX4QnomcCzZCzfsq3hansSpldkGgEb2zYPNoevdn8tLmHQVXyZdF/qvCZeJ5sr4PweVfvecKV0elUAZ7dlzspZbcxLWW3dlS1NiLvb2DZcuiMnihxQ8t32tDtd7wrAW3t3xWjV3a/HpkmMSGeZq397yypr2ON1q+/KFJWlSanT3D441jOiOJl+Z4Isdo1d6if+FUVcWK5oBXVYkxFcOJ4c3bTYZjRWci6XsR9eJ8bcNisaCua+p6FWsc3/cJgoAoiojjmDAMOT6e8n5nyOt3HT58GtE7OKDb2WPsuozH4xbXdRkMh+x3Duh0e+x3e238DdtDYi1a9HxCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/adfceb2629f0a577d32f3f76d1ebfd51/e4706/Syn1.avif 230w", "/en/static/adfceb2629f0a577d32f3f76d1ebfd51/d1af7/Syn1.avif 460w", "/en/static/adfceb2629f0a577d32f3f76d1ebfd51/48f1a/Syn1.avif 807w"],
              "sizes": "(max-width: 807px) 100vw, 807px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/adfceb2629f0a577d32f3f76d1ebfd51/a0b58/Syn1.webp 230w", "/en/static/adfceb2629f0a577d32f3f76d1ebfd51/bc10c/Syn1.webp 460w", "/en/static/adfceb2629f0a577d32f3f76d1ebfd51/9ec5a/Syn1.webp 807w"],
              "sizes": "(max-width: 807px) 100vw, 807px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/adfceb2629f0a577d32f3f76d1ebfd51/81c8e/Syn1.png 230w", "/en/static/adfceb2629f0a577d32f3f76d1ebfd51/08a84/Syn1.png 460w", "/en/static/adfceb2629f0a577d32f3f76d1ebfd51/d2a60/Syn1.png 807w"],
              "sizes": "(max-width: 807px) 100vw, 807px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/adfceb2629f0a577d32f3f76d1ebfd51/d2a60/Syn1.png",
              "alt": "Synology Automated Pan and Tilt Tour",
              "title": "Synology Automated Pan and Tilt Tour",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Menu Preferences, click on the plus to define positions. Assign a name, then arrows appear in the border area of the live image with which you can move to the position. Then save the position:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "704px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e871fb9b051db84045068d84c00b42dd/5ebd7/Syn2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAADhUlEQVQ4y3WT+29TZRjH+6f4g8GoiQgD07hFnBPH1q2u68rYwAXJzGA4dWFGJJMlTsYUL8EFgySEMaUyN6yZmsBkeGNjomDYSndpTy/nnLbn9NzaXYq/fUxPx9REf/jkyfvmzSff53nyOsav3+HydIqfZywmZ00WRZ2IrBKRM4RlnZ/mknw/K/LDXIobYZWgqJFQTWKKSXyNaMqwEVI6julbKSZuZZgOKVy+oTO/qCKJMWLRGKqiIEky4UgEw1DIWjqmoZM1/x+HoWcIhZcILuTIaFlMK4skp0grGUQ5SUKSSEgq03+o/BZSiMgK8wmFhfuIReYTacJSGkdSVZiayRGOmuSyOppuoGk6pmlwJyRzZVLi97sKN4MGMTWPlc+jL/+TVbSlVbTlPEnNxCEnJSJxlQXBslvSdQPD0DFMCyEyx7WvLzF42s8l/wij/gCfDwa4cD6A/3yxDvu/ITD6Hf6hUSav38SRTEpE4wpCvCA00I2iUDOXMSLjjHW5qX7YhesJNxUb3ZRv8uDcUIPzoRq2PlhN6SO1lG/2sumBCo73DOAQRQlJlu0WDaOIpmks51f5dugiLVuq8D3po6GsgVpnIzXbduMqbeaZzQ08tdHD8xW7aarey15vC58N+QsJk6QVZV12X7iSX+XLcyNs31DFroo9tDe2sr3ES3mJj2dL6mhtepm3urqpLNvJ+10HOdrZxsCpIRyqqpLNZm2RaZp21TWdlXt5AoPDeB7bwcf7m+l+oYVaZz3eMi9up4fjbx7hvbf72fZ4HftcTezY4uKD/jM4FEVZF/2dUOfen3nOfjLM04+6+KL3VS52NPNOy0u8VtlAT2MznTU+3qisxeesp/m5Jiq3ejhx7NP/Fuq6xtLKKhNjX9Hb5qP/4B7Gju7kiGcXJ93VXH3Fxbt11RyrdfGR182hqnpaSl2c/fB0UWhZ1nrLBSzLxMqtEJm9xtRwN+N9HQR62jnxYhuDHQc403qAwKF2Djftp7fzMCMn+3jdu4/AuQs40un0WirdpjBTWZbtRRV+TFSIkZTiBEMiv96WCS2ICPEEUiJBRoojJeKIskQ0UbiXcWQyGXsppmnZSQvSVCpti3XDJLOGZpjEZYu74SxTt3NMzOW4vJjjFyGLohmommG/cQiCQDQa/RexWMymeBbWiccFEnGBYDDKwIRI31WZUz+KhOYXmZmZJRwW+AsELMFGX9p9wgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e871fb9b051db84045068d84c00b42dd/e4706/Syn2.avif 230w", "/en/static/e871fb9b051db84045068d84c00b42dd/d1af7/Syn2.avif 460w", "/en/static/e871fb9b051db84045068d84c00b42dd/6b930/Syn2.avif 704w"],
              "sizes": "(max-width: 704px) 100vw, 704px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e871fb9b051db84045068d84c00b42dd/a0b58/Syn2.webp 230w", "/en/static/e871fb9b051db84045068d84c00b42dd/bc10c/Syn2.webp 460w", "/en/static/e871fb9b051db84045068d84c00b42dd/9a172/Syn2.webp 704w"],
              "sizes": "(max-width: 704px) 100vw, 704px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e871fb9b051db84045068d84c00b42dd/81c8e/Syn2.png 230w", "/en/static/e871fb9b051db84045068d84c00b42dd/08a84/Syn2.png 460w", "/en/static/e871fb9b051db84045068d84c00b42dd/5ebd7/Syn2.png 704w"],
              "sizes": "(max-width: 704px) 100vw, 704px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e871fb9b051db84045068d84c00b42dd/5ebd7/Syn2.png",
              "alt": "Synology Automated Pan and Tilt Tour",
              "title": "Synology Automated Pan and Tilt Tour",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Menu Monitoring, click on as Plus to define a monitoring tour. Assign a name and use the arrows to put the previously saved positions in the order in which they are to be approached. Then save.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "702px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0d3e17586558839df31a8560f02f8a07/d6331/Syn3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACcklEQVQ4y3WTy3LbOBBF9dHzAfmC/MdsJ7upPDaJ7TiJ7US2JIoiCRAkQLyZ1NRszwwoW2UnzuIWHg0e3O4GV+8uv7KTE62OHMbEVT1zsc1c7jKf95k3d5q3G837veX8YNmoSO8yYsoIm5E205pEa+LCWKle0DYNTVPz5Ubz93ng1Znlrw8T7y4z1+uB6rDH+QnrAyEEUvy9VjnPqDGR0kwIGW0yUlqMSVSHETU4uv4HZ18yotdo69EuPKty4eqgBv587biTkVp7mimybge60bCpNO8vJZtqpGoibQG6hPFpGR9U1oMNWBdYKTPxaZMQJqJspLcRYTzD5NF6RMqOTnSk5LE+Lpp8wIWEjxm3KGFcwBWHOQVuq5JuYs6JnOIyzjnTdJLbbcV6s2NT1XSyR6qR0TjqtmNT7dnua5pOLGcHbVg55zmITIiRmCIx3islWiHYVXuElHRCoIaBUY8YN1HVNYemWWK9UsheMRag956cSlMKKC2wlNIyV8OI1oZ5nskp831OdH3k/NrTdgrv3TGW8/Kddf4IPALiPTSS7qGiVwghl73yXIqubiPftp6PV5JxVKdYyXSy7inwqcO4pKDUcB8rwMj1XcRYy4dPA87Z0zc+hF8dPqisy60+xFM6D/vrKnJx47m57fHenmKFM7lnHJb5A6Q8g/TIcc6JQQe+bh1tJ+gX9+lX4GN3zjmmaWKydknjsfsCjNHjgmEceryd+J7jo5RdeTbuVPASMMYgZSn4uNSkHCoqHW1l4Ns2sK4c69axU556ODbDTPa5psRTyj/X9djNyKATF9czL978wx9v/+Xl2Q+MsXRC4f7/9f4Ds2ZUnPzADHIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d3e17586558839df31a8560f02f8a07/e4706/Syn3.avif 230w", "/en/static/0d3e17586558839df31a8560f02f8a07/d1af7/Syn3.avif 460w", "/en/static/0d3e17586558839df31a8560f02f8a07/19dff/Syn3.avif 702w"],
              "sizes": "(max-width: 702px) 100vw, 702px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0d3e17586558839df31a8560f02f8a07/a0b58/Syn3.webp 230w", "/en/static/0d3e17586558839df31a8560f02f8a07/bc10c/Syn3.webp 460w", "/en/static/0d3e17586558839df31a8560f02f8a07/de2ca/Syn3.webp 702w"],
              "sizes": "(max-width: 702px) 100vw, 702px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d3e17586558839df31a8560f02f8a07/81c8e/Syn3.png 230w", "/en/static/0d3e17586558839df31a8560f02f8a07/08a84/Syn3.png 460w", "/en/static/0d3e17586558839df31a8560f02f8a07/d6331/Syn3.png 702w"],
              "sizes": "(max-width: 702px) 100vw, 702px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0d3e17586558839df31a8560f02f8a07/d6331/Syn3.png",
              "alt": "Synology Automated Pan and Tilt Tour",
              "title": "Synology Automated Pan and Tilt Tour",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`List menu, then click Add to define an action rule.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "802px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/88f6eb9546f5697ba248d362d170faa5/5a6dd/Syn5.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABHUlEQVQoz6XRWU7DMBQFUO9/LyyBDwoSg4qgbVqpVWOH2I7H9xzYwEUJLWqREBA+jq7tj+tJXD92eNj02DUROmS8dAmNDehSgUsFjWfMZcD93qPuaFyzkc+YQGj9IEPcLRNm8wAlNVynoVsNJRWi9+CcEYMHpQSmDKZjnisnKaxLaHSH28Ubtioj5AztIhrjYH0eU7sElwj+ByERxHNtcFPtcfkkcXGlMNsUrAyjsoylYazsx3xpaJx/Z6EJlc4QrYuQNkBZh6ZzqOqCtWSYmNEOb/ML+pAmEgQzoS/l02tPKDwVQ2QiEDFozON4OjGUMJ8Wft3gb74pnE7knP9dMhyIDynK8Bl9jyGnOr2lUEpBSjlZXdeo1uvRdrvDO12dfUw23QngAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/88f6eb9546f5697ba248d362d170faa5/e4706/Syn5.avif 230w", "/en/static/88f6eb9546f5697ba248d362d170faa5/d1af7/Syn5.avif 460w", "/en/static/88f6eb9546f5697ba248d362d170faa5/903ec/Syn5.avif 802w"],
              "sizes": "(max-width: 802px) 100vw, 802px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/88f6eb9546f5697ba248d362d170faa5/a0b58/Syn5.webp 230w", "/en/static/88f6eb9546f5697ba248d362d170faa5/bc10c/Syn5.webp 460w", "/en/static/88f6eb9546f5697ba248d362d170faa5/85811/Syn5.webp 802w"],
              "sizes": "(max-width: 802px) 100vw, 802px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/88f6eb9546f5697ba248d362d170faa5/81c8e/Syn5.png 230w", "/en/static/88f6eb9546f5697ba248d362d170faa5/08a84/Syn5.png 460w", "/en/static/88f6eb9546f5697ba248d362d170faa5/5a6dd/Syn5.png 802w"],
              "sizes": "(max-width: 802px) 100vw, 802px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/88f6eb9546f5697ba248d362d170faa5/5a6dd/Syn5.png",
              "alt": "Synology Automated Pan and Tilt Tour",
              "title": "Synology Automated Pan and Tilt Tour",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then, in Surveillance Station, go to the Action Rule menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c8d9f7c3003a2311ad7723a0c2759061/ce6cc/Syn4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABm0lEQVQozz2PW08aYRRF+V1V01plQJiZ7z4DDHKxKBBaNbUl+tBomjRp04c2GJh6gRgvgfRPrmZG04eTnbPPzs5ZhW+Te35OZlzfrbi6+8v05oHZfMXvPw+kiyd+XT2SLpZMFysub5dMblakixWz+TLX6fU96fyJy3RBevtIYf1NyFbRY31jg7W1V+hkF6/ss9cfs+XVGHU+UCr5yMo2pZ0inbhIxSsSqCpeaRsbZ/kqndFHKoGg4IcWqQ2l8g6vN9/S6rzHWMfJ+CvC7fPl7DtCJyR1i7SGdq+ONo7GwTukdbS7x/iqwdHnc3TUpBCGAVIKwkAgtKR1WsbXhs2DM6RRxL0eVRHht36gjeHiOEQbRS3ZI5AGbzgmsBH20xFhFGWFIUKIfJRWJAOFNhrd7ud7d7+L0o64eYi1mpN+gDMSU99FaU3cG2KjmNpwgHbupVAKRCiQViGHI4yK6A0GaOs4PD3HWIMUVYSQVAOdq5ICKSUqIxQCGTxrITOzyT7MUOJWA6UMxrkcMWk2X+7POZkXif9UmZ9rlpGSf34uJ3QU5ljMAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8d9f7c3003a2311ad7723a0c2759061/e4706/Syn4.avif 230w", "/en/static/c8d9f7c3003a2311ad7723a0c2759061/d1af7/Syn4.avif 460w", "/en/static/c8d9f7c3003a2311ad7723a0c2759061/7f308/Syn4.avif 920w", "/en/static/c8d9f7c3003a2311ad7723a0c2759061/e1c99/Syn4.avif 1380w", "/en/static/c8d9f7c3003a2311ad7723a0c2759061/51b21/Syn4.avif 1551w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c8d9f7c3003a2311ad7723a0c2759061/a0b58/Syn4.webp 230w", "/en/static/c8d9f7c3003a2311ad7723a0c2759061/bc10c/Syn4.webp 460w", "/en/static/c8d9f7c3003a2311ad7723a0c2759061/966d8/Syn4.webp 920w", "/en/static/c8d9f7c3003a2311ad7723a0c2759061/445df/Syn4.webp 1380w", "/en/static/c8d9f7c3003a2311ad7723a0c2759061/5f214/Syn4.webp 1551w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8d9f7c3003a2311ad7723a0c2759061/81c8e/Syn4.png 230w", "/en/static/c8d9f7c3003a2311ad7723a0c2759061/08a84/Syn4.png 460w", "/en/static/c8d9f7c3003a2311ad7723a0c2759061/c0255/Syn4.png 920w", "/en/static/c8d9f7c3003a2311ad7723a0c2759061/b1001/Syn4.png 1380w", "/en/static/c8d9f7c3003a2311ad7723a0c2759061/ce6cc/Syn4.png 1551w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c8d9f7c3003a2311ad7723a0c2759061/c0255/Syn4.png",
              "alt": "Synology Automated Pan and Tilt Tour",
              "title": "Synology Automated Pan and Tilt Tour",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Assign a name and select Planned as the rule type, then click Next.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "699px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/96e1e05aa4e846014b4db6408aff3f68/3fe45/Syn6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAABgUlEQVQ4y5WT21LbMBCG/eA8AE/DIzAdynBoZ0IJ4RCCGzuRLMnaXTnA/d+RFccOdTLtxTdaH/T5X8nKVpZQGI/SUItygsmyxsXc4EfuWmJ9vbD4mTtcLSwuX1OtfYCqE2snWFlGZozBw8MMs9kjft1P8fLyBCIHZgYRgYnasSNe+20duIMRJJEprbFaKzATnCPcPQquJ4LlWkPXPErV1Y6hHKP2lALwVviW/26/6Cng5m6D89sGs7zAvDQoXMDSyihFHA1hUtQwPiYUZEppLN5yVJWBiICI4YlRaotSGaxNjcqnVGPYmjBd1W3iJgrjesS4kSgU4R7eMqxHSPPT3Cy2mkSye9AiA/g4w/k7YZ9Qdon/jy8Jh8JenF7qk/BeojGOCnvB39JD4j3hGEnAaAIjhE52uPW9TTnGPA/QleB9E+Vfl6b7G7YJu2N2iHiKtGFMnwVPrwJdcXuv++WGHWbe+3/axdhu0zCKUnD2fYPTqw+cfPvETc6wVQWlbXv0/gBHmBYlyva1LwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/96e1e05aa4e846014b4db6408aff3f68/e4706/Syn6.avif 230w", "/en/static/96e1e05aa4e846014b4db6408aff3f68/d1af7/Syn6.avif 460w", "/en/static/96e1e05aa4e846014b4db6408aff3f68/d20ce/Syn6.avif 699w"],
              "sizes": "(max-width: 699px) 100vw, 699px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/96e1e05aa4e846014b4db6408aff3f68/a0b58/Syn6.webp 230w", "/en/static/96e1e05aa4e846014b4db6408aff3f68/bc10c/Syn6.webp 460w", "/en/static/96e1e05aa4e846014b4db6408aff3f68/32d5d/Syn6.webp 699w"],
              "sizes": "(max-width: 699px) 100vw, 699px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/96e1e05aa4e846014b4db6408aff3f68/81c8e/Syn6.png 230w", "/en/static/96e1e05aa4e846014b4db6408aff3f68/08a84/Syn6.png 460w", "/en/static/96e1e05aa4e846014b4db6408aff3f68/3fe45/Syn6.png 699w"],
              "sizes": "(max-width: 699px) 100vw, 699px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/96e1e05aa4e846014b4db6408aff3f68/3fe45/Syn6.png",
              "alt": "Synology Automated Pan and Tilt Tour",
              "title": "Synology Automated Pan and Tilt Tour",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now define the actions, click Add, select the device type: Camera, select the desired camera, Action Monitoring, Loops per cycle (1-10) and Interval (1 second - 24 hours), Return position without or one of the positions, then continue`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "702px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/95e27692de7f641313818607f5bf1cea/d6331/Syn7.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACD0lEQVQ4y52T224TMRCG8+i8AG/DBSCEikClEVCiJm2Tza73EJ/tsZ1UXP/I3s2hFb3h4pPH9vr379mZmdIaw45DaYnDnjDwiNvK4Wcjsew0Vr3Gr0bhlinc9xq/mSp7OQ4hgEIYRyI47zHjQuJuucJi2WC+SHjYRngX8JQC9nHkEI8xlTiTAsETFaFLZlqbElSNxbqJSCnAUyy35XV/Ab2Iw4W7k6AxBl0/oKo2IMrifoL+i5mUEqztsK0ZHreE+yriar7HlnG0A4d2AcrSiJs4zQOE8VDGnpzOhBDohwHeE9Z1xGoT8el6j4oJCOMgXYC0r+ACuCFIbRGPglwItF0H7z0e64TFfcKHrwes6x2qpkXT8+JEWvoHWdA/F8wO66YFYwxdL4vDj98OqBgH13Y8aM4ianIspjWuHaQ2zwUb1pZabBjHprb4/P2AdtAwPhSBMXdjLgehsVOm5DYjcw5fPrlmDP2ww7r2+HGX8O7qgNWWoxUGgwnoNRU65bFpOba9LPO8x6RHLw1inASVUhBSYle6RUOqgOUmYdNrsKOgoRODDedLDKFVhJ32Z4e5DlNKiDEWUu6EPWGfxjh/eCTPU6RzxyQqHZRiPBV5EXTOwVpX/nTGTeNLjCXoUiajs0ZSyaG1tpyfWk9DKQut3avVHwLBGCp9vnyIeD9PeHvzhDdf/uC6DtBCgAtdWvIvs6QOhcJLZ00AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95e27692de7f641313818607f5bf1cea/e4706/Syn7.avif 230w", "/en/static/95e27692de7f641313818607f5bf1cea/d1af7/Syn7.avif 460w", "/en/static/95e27692de7f641313818607f5bf1cea/19dff/Syn7.avif 702w"],
              "sizes": "(max-width: 702px) 100vw, 702px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/95e27692de7f641313818607f5bf1cea/a0b58/Syn7.webp 230w", "/en/static/95e27692de7f641313818607f5bf1cea/bc10c/Syn7.webp 460w", "/en/static/95e27692de7f641313818607f5bf1cea/de2ca/Syn7.webp 702w"],
              "sizes": "(max-width: 702px) 100vw, 702px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95e27692de7f641313818607f5bf1cea/81c8e/Syn7.png 230w", "/en/static/95e27692de7f641313818607f5bf1cea/08a84/Syn7.png 460w", "/en/static/95e27692de7f641313818607f5bf1cea/d6331/Syn7.png 702w"],
              "sizes": "(max-width: 702px) 100vw, 702px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/95e27692de7f641313818607f5bf1cea/d6331/Syn7.png",
              "alt": "Synology Automated Pan and Tilt Tour",
              "title": "Synology Automated Pan and Tilt Tour",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Set schedule, set everything active for continuous tour. Then finish.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "697px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3d95aa89fc9d9098afcb4207ec026aa5/7422e/Syn8.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACq0lEQVQ4y3WT228bRRSH99/liSY4l0qVKKioSFTiEQlRkNquk0gpSilRCVWaxAmpd9ch9ho7dhJf1vZ6b3OxA+L1Q7O2G6TAw7fnt785c/bM6owVZ5LhOGEUpaRSorSiOUw4vYz4rZlw3EzY9yNKjZiTZoJzFeN1I+qDGKkUcXZLlAqscDymWqvh132CYcpVb0LtQvNHW9O61rQ6t7HdndC41Pm68UZjhdaSiZZI04ySWLVuSLneo+pHNNuKYCDI0gyRZUx1ys0kQ4mIJBowDDqkSYJIM4ajjGZb4DckYSiYaIGQEqta7/D9lsOPe2V+Lb1nr1TmzaHhPbsHZfZKHr+UXN4cubl+e1Jh79hl/7TCoXPG7mGFZzs1Wp2Um6nC2j+64pvtdxQ2XFZsl3vPHQq2w2rR6DIrRTdn6YXD6ob3QZtYsF2W7TJrmy5vnQQpNNa7Uosffj5guXjG/a0KhaLH2mYl15/MtcHo9a1bvfCXbI8H2xVK5wlxrGcdPv3pgMJGhfXNuwWNt/CNt9Driw/ZLg+2Pfa9hCzVWF7lks+/O2E53+CyZLsU8mN6fGyOX/RyPmjb494Ll5UN07HJd/jo2yqnfsqf5h92r9s8feWy9rzFfdvns5dVHr+q8mS3xuPX53y1W8358vU5T+b60U6VL3ZqrNs+q8+afLrVoDOKmU40lhACITKSTHLVU5w3FbULxcX1/+O3FL83FO2OIk4kQs5GRimFZR4zJNOJZDJRCKHoDzXBf9AfaNJU5cNs8rVe7Ff5cFtyXjk35tEkTad3uZmzKCLlgtsaVhzH9Pp9+sGAfhAQDGZx9j6j2zPrQzrdgE7P5AxzL4qTvNi/sYSQBIOQcByTpukdkjwmjMKMs7rgyBF8Xb7hYekvHh7/jddTRGHIaBznV+8fshy9b5dFXVkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d95aa89fc9d9098afcb4207ec026aa5/e4706/Syn8.avif 230w", "/en/static/3d95aa89fc9d9098afcb4207ec026aa5/d1af7/Syn8.avif 460w", "/en/static/3d95aa89fc9d9098afcb4207ec026aa5/eb50a/Syn8.avif 697w"],
              "sizes": "(max-width: 697px) 100vw, 697px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3d95aa89fc9d9098afcb4207ec026aa5/a0b58/Syn8.webp 230w", "/en/static/3d95aa89fc9d9098afcb4207ec026aa5/bc10c/Syn8.webp 460w", "/en/static/3d95aa89fc9d9098afcb4207ec026aa5/458b7/Syn8.webp 697w"],
              "sizes": "(max-width: 697px) 100vw, 697px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d95aa89fc9d9098afcb4207ec026aa5/81c8e/Syn8.png 230w", "/en/static/3d95aa89fc9d9098afcb4207ec026aa5/08a84/Syn8.png 460w", "/en/static/3d95aa89fc9d9098afcb4207ec026aa5/7422e/Syn8.png 697w"],
              "sizes": "(max-width: 697px) 100vw, 697px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3d95aa89fc9d9098afcb4207ec026aa5/7422e/Syn8.png",
              "alt": "Synology Automated Pan and Tilt Tour",
              "title": "Synology Automated Pan and Tilt Tour",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      